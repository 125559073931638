@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --font-family-lato: "Lato", sans-serif;
  --font-family-montserrat: "Montserrat", sans-serif;
  --fs-11: 11px;
  --fs-14: 14px;
  --fs-15: 15px;
  --fs-16: 16px;
  --fs-17: 17px;
  --fs-20: 20px;
  --fs-30: 30px;
  --lh-34: 34px;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --primary-color: #e50d0d;
  --primary-color-2: #ff5659;
  --black-color: #000000;
  --white-color: #ffffff;
  --gray-color: #989898;
  --light-color: #fcfaf3;
  --light-brown-color: #e7e1bd;
  --val-0: 0;
  --val-26: 26px;
  --val-30: 30px;
  --brd-1-black: 1px solid #000;
}

* {
  margin: var(--val-0);
  padding: var(--val-0);
  box-sizing: border-box;
}

body {
  font-family: var(--font-family-lato) !important;
  color: var(--black-color);
  background-color: var(--white-color);
  font-size: var(--fs-14);
  font-weight: var(--fw-400);
  position: relative;
  min-height: 100vh;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-montserrat) !important;
}
.bg-gray {
  background-color: #f5f5f5 !important;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  column-gap: 8px;
  position: fixed;
  margin: 0px auto;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 111;
  background-color: #fff;
}

.loader .dot {
  width: 12px;
  height: 12px;
  background-color: var(--black-color);
  border-radius: 50%;
  animation: loading 1s infinite alternate;
}

.loader .dot:nth-child(1) {
  background-color: var(--primary-color);
  animation-delay: -0.25s;
}

.loader .dot:nth-child(2) {
  background-color: var(--black-color);
  animation-delay: -0.5s;
}

.loader .dot:nth-child(3) {
  background-color: var(--primary-color);
  animation-delay: -0.75s;
}

.loader .dot:nth-child(4) {
  background-color: var(--black-color);
  animation-delay: -1s;
}

@keyframes loading {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(5px);
  }
}

.container-fluid {
  padding: var(--val-0) var(--val-30);
}
ul {
  margin: var(--val-0);
  padding: var(--val-0);
}

ul li {
  list-style-type: none;
}
p,
.btn {
  font-family: var(--font-family-lato) !important;
}

a,
a:hover {
  text-decoration: none;
}
.mb-8 {
  margin-bottom: 8em;
}
.mr-0 {
  margin-right: 0px;
}
.float-right {
  float: right;
}
.btn-custom-mr {
  margin-right: 3em;
}
.mt-m-90 {
  margin-top: -90px;
}

.btn-main,
.btn-main:hover,
.btn-main:focus,
.btn-main:checked + .btn,
.btn-main.active,
.btn-main.show,
.btn-main:first-child:active,
:not(.btn-main) + .btn-main:active {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 23.9px;
  letter-spacing: 0.28em;
  padding: 15px 35px;
  border-radius: 30px;
  border: var(--brd-1-black);
  background-color: var(--black-color);
}

.btn-main:hover,
.btn-main:focus,
.btn-main:checked + .btn,
.btn-main.active,
.btn-main.show,
.btn-main:first-child:active,
:not(.btn-main) + .btn-main:active {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.btn-main-inactive {
  background-color: white;
  color: black;
}
.btn-main-outline {
  padding: 11px 11px;
  border-radius: var(--val-30);
  height: 46px;
  border: var(--brd-1-black);
  font-size: var(--fs-11);
  font-weight: var(--fw-700);
  line-height: var(--val-26);
  letter-spacing: 0.28em;
  transition: 0.5s all;
  background-color: transparent !important;
  color: var(--black-color);
}
.btn-main-outline:hover {
  background: var(--primary-color) !important;
  color: var(--white-color) !important;
  transition: 0.5s all;
}
.btn-skip,
.btn-skip:hover,
.btn-skip:focus,
.btn-skip:checked + .btn,
.btn-skip.active,
.btn-skip.show,
.btn-skip:first-child:active,
:not(.btn-skip) + .btn-skip:active {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 23.9px;
  letter-spacing: 0.28em;
  padding: 15px 25px;
  border-radius: 30px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  color: var(--black-color);
}
.btn-edit {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 23.9px;
  letter-spacing: 0.28em;
  padding: 15px 35px;
  border-radius: 30px;
  height: auto;
}
.btn-sm-outline {
  font-size: var(--fs-16);
  font-weight: var(--fs-400);
  letter-spacing: 0.28em;
  color: var(--black-color);
  border: 1px solid var(--black-color);
  border-radius: 30px;
  width: 87px;
  height: 39px;
  transition: 0.5s all;
}
.btn-sm-outline:hover {
  background: var(--black-color);
  color: var(--white-color);
  transition: 0.5s all;
}
.css-1umw9bq-MuiSvgIcon-root {
  width: 1em !important;
  height: 0.8em !important;
  fill: #000 !important;
}
.btn-event-outline {
  border-radius: 25px;
  border: 1px solid var(--black-color);
  color: var(--black-color);
  font-size: 18px;
  font-weight: var(--fw-400);
  line-height: 149.378%;
  transition: 0.5s all;
}
.btn-event-outline:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--white-color);
  transition: 0.5s all;
}
.btn-main2,
.btn-main2:hover,
.btn-main2:focus,
.btn-main2:checked + .btn,
.btn-main2.active,
.btn-main2.show,
.btn-main2:first-child:active,
:not(.btn-main2) + .btn-main2:active {
  font-size: 11px;
  font-weight: var(--fw-700);
  line-height: var(--lh-34);
  letter-spacing: 0.28em;
  padding: 11px 25px;
  border-radius: 30px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  text-transform: uppercase;
}

.navbar {
  box-shadow: 0px 14px 24px 0px #0000000f;
  height: 100px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-size: var(--fs-14);
  font-weight: var(--fw-400);
  line-height: var(--lh-34);
  letter-spacing: 0.07em;
  color: var(--black-color);
}

.navbar-nav .btn-main-outline {
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: var(--fw-700);
}

.navbar-nav .btn-main-outline:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.dropdown-toggle:hover .dropdown-menu {
  display: block;
  transition: 0.5s all;
}
.navbar-nav .dropdown-menu,
.mob-nav .dropdown-menu {
  box-shadow: 0px 4px 11px 0px #00000014;
  padding: var(--val-0);
  border: var(--val-0);
  transition: 0.5s all;
}
.navbar-nav .dropdown-menu li,
.mob-nav .dropdown-menu li {
  padding: 0px 20px;
}
.navbar-nav .dropdown-menu li:last-child,
.mob-nav .dropdown-menu li:last-child {
  margin-bottom: 15px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
  color: var(--bs-dropdown-link-color);
}
.navbar-nav .dropdown-toggle::after,
.mob-nav .dropdown-toggle::after {
  display: none;
}
.navbar-nav .dropdown-item,
.mob-nav .dropdown-item {
  font-size: 13px;
  line-height: var(--lh-34);
  letter-spacing: 0.05em;
  color: var(--black-color);
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
}
.navbar-nav .dropdown-menu[data-bs-popper],
.mob-nav .dropdown-menu[data-bs-popper] {
  left: initial;
  right: 0px;
}
.slider .container-fluid {
  padding: 0px 70px !important;
}
.main-slider .carousel-item,
.main-slider .carousel {
  border-radius: 20px;
}
.main-slider .slider-img {
  border-radius: 20px;
  width: 100%;
  height: 450px;
  object-fit: cover;
  box-shadow: 0px 4px 54px 0px #0000001c;
}
.main-slider .carousel-control-prev,
.main-slider .carousel-control-next {
  display: none;
}
.main-slider .carousel-indicators {
  bottom: -55px;
}
.carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: transparent;
  border: 1px solid #9d9d9d;
}
.carousel-indicators .active {
  background-color: var(--primary-color-2) !important;
  border-color: var(--primary-color-2) !important;
}
.home-calculate-form .tagline {
  font-size: var(--fs-17);
  font-weight: var(--fw-500);
}
.home-calculate-form .btn-main,
.home-calculate-form .btn-main:hover,
.home-calculate-form .btn-main:focus,
.home-calculate-form .btn-main:checked + .btn,
.home-calculate-form .btn-main.active,
.home-calculate-form .btn-main.show,
.home-calculate-form .btn-main:first-child:active,
.home-calculate-form :not(.btn-main) + .btn-main:active {
  font-size: 11px;
  padding: 11px 26px;
  font-weight: var(--fw-400);
  line-height: 23.9px;
  letter-spacing: 0.28em;
  border-radius: 30px;
  border: var(--brd-1-black);
  background-color: var(--black-color);
}

.home-calculate-form .btn-main:hover,
.home-calculate-form .btn-main:focus,
.home-calculate-form .btn-main:checked + .btn,
.home-calculate-form .btn-main.active,
.home-calculate-form .btn-main.show,
.home-calculate-form .btn-main:first-child:active,
.home-calculate-form :not(.btn-main) + .btn-main:active {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}
.heading {
  width: 520px;
  margin: 0px auto;
}
.heading h2,
.heading2 h2 {
  font-family: var(--font-family-montserrat) !important;
  color: #131313;
  font-size: var(--fs-30);
  font-weight: var(--fw-700);
  line-height: 34px;
  letter-spacing: 1.4px;
}
.heading2 h2 span {
  display: block;
}
.heading p {
  font-size: var(--fs-16);
  font-weight: var(--fw-300);
}

.video-player-container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
}
iframe {
  border-radius: 20px;
}

.video-player-container button.plyr__control.plyr__control--overlaid {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.2) !important;
  padding: 0;
}

.video-player-container button.plyr__control.plyr__control--overlaid:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
}

.video-player-container .plyr__control--overlaid svg {
  display: none;
}

.video-player-container .plyr--video .plyr__controls {
  opacity: 0;
}

.video-player-container:hover .plyr--video .plyr__controls {
  opacity: 1 !important;
}

.video-player-container .plyr__video-wrapper {
  display: block;
  position: relative;
  background-color: transparent;
  aspect-ratio: 1280 / 720 !important;
}

.video-player-container .plyr__video-wrapper:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.video-player-container .plyr--playing .plyr__video-wrapper:after {
  opacity: 0;
}

.video-player-container .plyr__poster {
  background-size: cover;
}

.video-player-container:hover button.plyr__control.plyr__control--overlaid {
  opacity: 0.65;
}

#video-player canvas.data-poster {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  visibility: hidden;
}

#video-player.active canvas.data-poster {
  opacity: 1;
  visibility: visible;
}

#video-player {
  background: transparent !important;
  overflow: hidden;
  margin-bottom: -1px;
}

.video-logo {
  width: 278px;
}

.package-block {
  padding: 1.5em;
  box-shadow: 0px 7px 44px 0px rgba(1, 1, 1, 0.06);
  background-color: #fff;
  border-radius: 20px;
}
.package-block h4 {
  font-size: var(--fs-17);
  font-weight: var(--fw-700);
  line-height: var(--lh-34);
  letter-spacing: 0.07em;
}
.package-block img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}
.package-block p {
  font-size: var(--fs-15);
  font-weight: var(--fw-400);
  line-height: 26.14px;
}
.package-block .btn-main-outline {
  padding: 11px 26px;
  line-height: 19px;
  height: 40px;
}
.why-choose-us {
  overflow: hidden;
}
.why-choose-us .left-block {
  width: 40%;
}
.why-choose-us .left-block img {
  width: 100%;
  height: 462px;
  object-fit: cover;
}
.why-choose-us .right-block {
  width: 60%;
  background-color: #2b2b2b;
}
.why-choose-us .border {
  border: 2px solid #3c3c3c;
  border-radius: 10px;
  height: 130px;
}
.why-choose-us h5 {
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
  line-height: 25.6px;
  letter-spacing: 0.04em;
  color: var(--white-color);
}
.why-choose-us p {
  font-size: var(--fs-17);
  font-weight: var(--fw-300);
  line-height: 21.76px;
  letter-spacing: 0.04em;
  color: #7d7d7d;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.occassion-slider .slider-item {
  position: relative;
  margin: 0px 15px;
  box-shadow: 0px 4px 19px 0px #00000014;
  background-color: var(--white-color);
  border-radius: 20px;
  padding: 1.3em;
  width: auto !important;
}
.occassion-slider .slide-img-block {
  position: relative;
  border-radius: 10px;
}
.occassion-slider .slide-img-block:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50.5%,
    rgba(0, 0, 0, 0.72) 80%
  );
  z-index: 1;
}
.occassion-slider .slider-item img.w-100 {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  display: block;
  border: 1px solid #eeeeee;
  border-radius: 10px;
}
.occassion-slider .slider-item h3 {
  font-family: var(--font-family-montserrat) !important;
  position: absolute;
  bottom: 135px;
  margin: 0 auto;
  display: table;
  left: 0;
  right: 0;
  font-size: 22px;
  font-weight: var(--fw-700);
  line-height: 26px;
  letter-spacing: 0.07em;
  color: var(--white-color);
  z-index: 111;
  width: 80%;
  text-align: center;
}
.occassion-slider .slider-item p {
  font-size: var(--fs-15);
  font-weight: var(--fw-400);
  line-height: 23px;
  letter-spacing: 0.07em;
  padding-right: 0.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slick-list {
  padding: 1.5em 0 !important;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
.slick-prev {
  right: 60px;
  left: initial !important;
}
.slick-arrow img,
.slick-prev,
.slick-next {
  width: 15px;
  height: 15px;
  margin: 0px 5px;
  background-color: transparent !important;
  z-index: 1111 !important;
  position: relative;
}
.slick-next,
.slick-prev {
  top: -30px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  text-align: center;
  line-height: 40px !important;
}
.slick-next:hover,
.slick-prev:hover {
  border: 1px solid #413c3c;
}
.bg-light-gray {
  position: relative;
}
.bg-light-gray:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 350px;
  background-color: #f5f5f5;
  z-index: -1;
}
.recent-slider-item {
  position: relative;
  margin: 0px 0px;
  border-radius: 20px;
  padding: 1.3em;
  width: auto !important;
}
.recent-slider-item:focus-visible {
  outline: 0px;
}
.recent-slider-item img {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
}
.quote-area {
  background: url(assets/img/quote-bg.jpg) center center no-repeat;
  background-size: cover;
  width: 88.5%;
  height: 370px;
  position: relative;
  border-radius: 20px;
  margin: 0px auto;
  color: var(--white-color);
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.quote-area:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  border-radius: 20px;
}
.quote-content {
  position: relative;
  z-index: 1;
  width: 500px;
  margin: 0 auto;
}
.quote-content h1 {
  font-family: var(--font-family-montserrat) !important;
  font-size: var(--fs-30);
  font-weight: var(--fw-400);
  letter-spacing: 0.08em;
}
.quote-content p {
  font-size: var(--fs-15);
  font-weight: var(--fw-400);
  line-height: 26px;
}
.quote-content .btn {
  border-color: var(--white-color) !important;
  color: var(--white-color) !important;
}
.quote-content .btn:hover {
  border-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  background-color: var(--primary-color) !important;
}
.testimonials .t-heading {
  position: absolute;
  margin: 0px auto;
  display: table;
  right: 0px;
  left: 40%;
}
.testimonial-block .testi-img {
  width: 40%;
  position: relative;
  right: -38px;
}
.testimonial-block .testi-img img {
  width: 350px;
  height: 450px;
  object-fit: cover;
  border-radius: 20px;
}

.testimonial-block .testi-img:after {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  right: -18px;
  top: -20px;
  background-color: #fde8b1;
  z-index: -1;
}
.testi-content {
  width: 100%;
  box-shadow: 0px 0px 148.3px -50px #00000040;
  background-color: var(--white-color);
  position: relative;
  z-index: 11;
  top: 20px;
}
.testi-content p {
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
  line-height: var(--lh-34);
}
.testi-content h5 {
  font-size: 25px;
  font-weight: var(--fw-400);
  line-height: 34.25px;
  color: var(--primary-color);
}
.testi-content span {
  font-size: var(--fs-15);
  font-weight: var(--fw-500);
  line-height: 20.55px;
}
.rating .star {
  color: #d9d9d9;
  font-size: var(--fs-17);
}
.rating .star.active {
  color: #ffc328;
}
.testimonials .slick-prev,
.testimonials .slick-next {
  top: auto !important;
  bottom: -20px !important;
}
.testimonials .slick-prev {
  right: 36%;
}
.testimonials .slick-next {
  left: 63%;
}
.categories .cat-block {
  position: relative;
  border-radius: 10px;
}
.categories .cat-img {
  position: relative;
  border-radius: 10px;
}
.categories .cat-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50.5%,
    rgba(0, 0, 0, 0.72) 80%
  );
  z-index: 1;
}
.categories .cat-img img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 10px;
}

.categories .overlay {
  text-align: center;
}
.categories h3 {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 50px;
  margin: 0 auto;
  display: table;
  left: 0;
  right: 0;
  font-size: 22px;
  font-weight: var(--fw-700);
  line-height: 26px;
  letter-spacing: 0.07em;
  color: var(--white-color);
  z-index: 111;
}
.accordion-item {
  border: 0px;
}
.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: var(--primary-color-2);
}
.accordion-header {
  border-top: 1px solid var(--black-color);
}
.accordion {
  border-bottom: 1px solid var(--black-color);
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button {
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
  line-height: 29.88px;
  color: #636363;
  display: flex;
  justify-content: space-between;
}
.accordion-button::after {
  display: none;
}
.text-end svg {
  color: var(--primary-color-2);
  font-size: 28px;
}
.accordion-body {
  font-size: 19px;
  font-weight: var(--fw-300);
  padding-top: 0px;
}
.event-tab-section {
  background: var(--light-color);
}
.event-tab-section .nav-tabs {
  border-bottom: var(--val-0);
  position: relative;
}
.event-tab-section .nav-tabs:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #d9d9d9;
  z-index: 0;
}
.event-tab-section .nav-tabs .nav-link span.number {
  color: var(--white-color);
  font-size: 24px;
  font-weight: var(--fw-400);
  line-height: 31px;
  width: 31px;
  height: 31px;
  background-color: var(--gray-color);
  border-radius: 50%;
  text-align: center;
  margin-bottom: 10px;
}
.event-tab-section .nav-tabs .nav-link strong {
  color: #000;
  font-size: 12px;
  font-weight: var(--fw-700);
  line-height: 24px;
  letter-spacing: 2.16px;
  text-align: center;
}
.event-tab-section .nav-tabs .nav-link.completed span.number {
  background-color: var(--black-color);
}
.event-tab-section .nav-tabs .nav-link,
.event-tab-section .nav-tabs .nav-link.active {
  background-color: var(--light-color);
  position: relative;
  z-index: 1;
  width: 170px;
  border-bottom: 6px solid var(--light-color);
}
.event-tab-section .nav-tabs .nav-link.active span.number {
  background-color: var(--primary-color-2);
}
.event-tab-section .nav-tabs .nav-item.show .nav-link,
.event-tab-section .nav-tabs .nav-link.active,
.event-tab-section .nav-tabs .nav-link:hover {
  border-top: var(--val-0);
  border-left: var(--val-0);
  border-right: var(--val-0);
  border-bottom: 6px solid var(--primary-color-2);
}
.event-tab-section .nav-tabs .nav-link:focus {
  outline: 0;
}
.event-tab-content {
  overflow: hidden;
  border-radius: 15px;
  border: 1px solid #fff;
  background: rgba(213, 213, 213, 0);
  box-shadow: 0px 4px 15px 0px rgba(233, 233, 233, 0.6);
}
.event-tab-content .tab-content {
  padding-top: 2.5em !important;
  padding-bottom: 8em;
}
.event-tab-content h4,
#make-payment h4 {
  color: #131313;
  font-size: var(--fs-20);
  font-weight: var(--fw-700);
  line-height: 34px;
  letter-spacing: 1.4px;
}
.event-tab-content .event-item {
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  background: var(--white-color);
  width: 20%;
  height: 214px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-tab-content h6 {
  color: #000;
  font-size: 17px;
  font-weight: var(--fw-500);
  line-height: 22px;
  letter-spacing: 2.76px;
  height: 40px;
  text-align: center;
}
.event-tab-content .event-item.selected {
  border-radius: 18px;
  border: 1px solid #e1e1e1;
  background: var(--primary-color);
}
.event-tab-content .event-item.selected h6 {
  color: var(--white-color);
}
.event-tab-content .event-item.selected .event-icon {
  filter: invert(1) brightness(11);
}
.review-order-n {
  background-color: #f8f8f8;
  border-radius: 13px;
}
.review-order-n .text-red {
  color: var(--primary-color-2);
}
.order-top h3 {
  color: #242731;
  font-size: 32px;
  line-height: 36px;
  font-weight: var(--fw-600);
}
.order-top span {
  font-size: 16px;
}
.order-top .border strong {
  font-size: 15px;
  color: #131313;
  font-weight: var(--fw-700);
}
.order-top .border h5 {
  font-size: 20px;
  color: #000000;
  font-weight: var(--fw-700);
}
.order-top .border .btn-proceed {
  background-color: var(--black-color);
  font-size: 16px;
  font-weight: var(--fw-600);
  border-radius: 4px;
  padding: 10px 25px;
  border: 0px;
}
.booking-summary h5 {
  color: #242731;
  font-size: 18px;
  font-weight: var(--fw-600);
}
.day-wise .col-md-4 {
  padding: 10px;
}
.day-wise span {
  font-size: 15px;
}
.day-wise strong {
  font-size: 18px;
  font-weight: var(--fw-700);
}
.review-order-n .btn-edit-outline,
.review-order-n .btn-edit-outline:focus,
.review-order-n .btn-edit-outline:hover {
  background-color: transparent;
  font-size: 16px;
  font-weight: var(--fw-600);
  border-radius: 4px;
  padding: 10px 25px;
  border: 1px solid var(--black-color);
  color: var(--black-color);
}
.day-wise td {
  min-width: 250px;
}
.review-order-n .bg-white {
  border-radius: 8px;
}
.border-bottom-0 {
  border-bottom: 0px;
}
.booking-summary .btn-edit2,
.booking-summary .btn-edit2:hover .booking-summary .btn-edit2:focus {
  background-color: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #000;
  font-size: 15px;
  padding: 0px;
}
.by-day .date {
  color: #242426;
  font-size: 18px;
  font-weight: var(--fw-700);
}
.by-day td,
.by-day th {
  padding: 20px;
  font-size: 14px;
}
.by-day th {
  font-weight: var(--fw-700);
}
.bg-light {
  background-color: #e2effa !important;
}
#customize-event {
  width: 1132px;
}

#customize-event .nav-tabs {
  border-bottom: 0px;
}
#customize-event .nav-tabs .nav-item {
  width: 48%;
  text-align: center;
  gap: 1em !important;
}
#customize-event .nav-tabs .nav-item .nav-link,
#customize-event .nav-tabs .nav-item .nav-link.active {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--black-color);
  background: rgba(109, 109, 109, 0);
  padding: 1.5em;
  color: var(--black-color);
  font-size: 15px;
  font-weight: var(--fw-700);
  line-height: 34px;
  letter-spacing: 4.2px;
}
#customize-event .nav-tabs .nav-item .nav-link.active {
  border: 1px solid #a9a9a9;
  color: var(--primary-color);
  background-color: #f7f7f7;
  position: relative;
}

#customize-event form label,
#review-order label,
.profile-tab-content label,
.contact-form label {
  color: var(--black-color);
  font-size: 15px;
  font-weight: var(--fw-700);
  line-height: 34px;
  letter-spacing: 17%;
}

#customize-event form .form-control,
.custom-modal .form-control,
#review-order .form-control,
.profile-tab-content .form-control,
.contact-form .form-control {
  color: var(--black-color);
  line-height: 20px;
  letter-spacing: 1.05px;
  border: 1px solid #000000;
  border-radius: 30px;
  padding: 15px;
  font-family: var(--font-family-lato) !important;
  font-size: 12px;
  font-weight: var(--fw-600);
  text-align: left;
}
#customize-event form .form-control::placeholder,
.custom-modal .form-control::placeholder,
#review-order .form-control::placeholder,
.profile-tab-content .form-control::placeholder,
.contact-form .form-control::placeholder {
  text-transform: uppercase;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: none;
}
.css-1oqp33u-placeholder {
  color: var(--black-color);
  line-height: 20px;
  letter-spacing: 1.05px;
  font-family: var(--font-family-lato) !important;
  font-size: 12px !important;
  font-weight: var(--fw-600);
  text-align: left;
  text-transform: uppercase;
}

.MuiFormControl-root {
  width: 100% !important;
  border: 0px solid #636363 !important;
}

#customize-event form .form-control::placeholder,
.modal-body .form-control::placeholder,
#review-order .form-control::placeholder,
.profile-tab-content .form-control::placeholder,
.contact-form .form-control::placeholder {
  color: var(--black-color);
}

#customize-event form .form-control:focus,
.modal-body .form-control:focus,
#review-order .form-control:focus,
.profile-tab-content .form-control:focus,
.contact-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

#customize-event .number-list a {
  border-radius: 25px;
  border: 1px solid #000;
  padding: 10px 20px;
  color: var(--black-color);
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 149.378%;
}
#customize-event .number-list a.selected {
  border-color: #a9a9a9;
  font-weight: var(--fw-700);
  color: var(--primary-color);
  background-color: #f4f4f4;
}
#customize-event .add-ons {
  border-radius: 20px;
  border: 1px solid #d6e1e9;
  background: #f3f8fc;
  height: 176px;
  position: relative;
  margin-bottom: 2em !important;
}
#customize-event .add-ons button {
  display: table;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 35%;
  width: 200px;
  background-color: transparent !important;
  color: var(--black-color);
}

#review-order .payment-method,
#make-payment .payment-method {
  margin-top: 2em !important;
}

#review-order .p-block,
#make-payment .p-block {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  width: 100%;
  min-height: 150px;
}

#review-order .payble-now,
#review-order .total-pay-list {
  font-size: 21px;
  font-weight: 400;
  line-height: 25.2px;
  color: var(--black-color);
}
#review-order .p-block .payment-label {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 20px;
  color: #636363;
}

#review-order .payment-block {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
#review-order .pay-list li span {
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
  line-height: 24px;
}
#review-order .pay-list li span.s-text {
  color: #636363;
  width: 235px;
  display: block;
}

#review-order .pay-list li span.price {
  color: var(--black-color);
}

#review-order .total-pay-list {
  background-color: #fcfaf3;
}
#make-payment label {
  color: var(--black-color);
  font-size: 13px;
  font-weight: var(--fw-700);
  line-height: 34px;
}
#make-payment input,
#make-payment select {
  color: var(--black-color);
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 20px;
  letter-spacing: 1.05px;
  border: 1px solid #000;
  border-radius: 30px;
  padding: 10px 15px;
}
#make-payment input::placeholder,
#make-payment select::placeholder {
  color: var(--black-color);
  font-size: 14px;
  font-weight: var(--fw-400);
  line-height: 20px;
  letter-spacing: 1.05px;
}
.css-1py2szx-control:hover {
  border-color: hsl(0deg 0% 0%);
}
.css-15nly8p-singleValue {
  color: var(--black-color) !important;
  font-size: 12px !important;
  font-weight: var(--fw-600);
  text-align: left;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1.05px;
}
#make-payment .mb-4 {
  margin-bottom: 0.5rem !important;
}
#make-payment .payment-block {
  background-color: #f9f9f9;
  border-radius: 10px;
}
#accordion .card {
  border: 0px;
}
#accordion .card .card-header {
  border-bottom: 0px;
  padding: 0px;
  background-color: transparent !important;
}
#accordion .card .card-header a {
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: 19px;
  font-weight: var(--fw-700);
  display: block;
  text-align: left;
  padding: 15px 25px;
  border-radius: 30px;
}
#accordion .card .card-header a:focus {
  border: 0;
}
#accordion .card .card-header svg {
  float: inline-end;
}
.w-560 {
  width: 560px;
}
.modal-dialog {
  max-width: 1000px !important;
  min-height: 574px !important;
  width: 100%;
}
.custom-modal h4 {
  color: #131313;
  font-size: 30px;
  font-weight: var(--fw-700);
  line-height: var(--lh-34);
  letter-spacing: 1.4px;
}
.custom-modal h5 {
  font-weight: var(--fw-600);
}
.c-block ul li {
  list-style-type: disc;
  font-size: 12px;
  margin-left: 15px;
}
.close-btn {
  border-radius: 50%;
  padding: 15px;
  background-color: var(--white-color);
  border: 1px solid var(--black-color);
  color: var(--black-color);
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: var(--lh-34);
  letter-spacing: 4.48px;
  position: absolute;
  right: 55px;
}

.close-btn span {
  display: none;
}

.btn-sign.btn-primary {
  min-width: 429px;
  color: var(--black-color);
  font-size: var(--fs-16);
  font-weight: var(--fw-600);
  line-height: var(--lh-34);
  letter-spacing: 4.48px;
  border-radius: 30px;
  border: 1px solid var(--black-color) !important;
  height: 55px;
  padding: 11px 26px;
  background-color: transparent !important;
}

.custom-modal label {
  color: var(--black-color);
  font-size: var(--fs-16);
  font-weight: var(--fw-500);
  line-height: var(--lh-34);
}
.modal-body .form-control {
  width: 560px;
}
.add-ons-modal li {
  margin-bottom: 3em;
}
.add-ons .btn {
  width: 170px;
}
.custom-modal .label-addons {
  font-size: var(--fs-20) !important;
  font-weight: var(--fw-700) !important;
  line-height: var(--lh-34) !important;
  letter-spacing: 0.07em !important;
  color: var(--black-color);
  display: block;
}
.add-ons-modal li {
  border: 2px solid #d9d9d9;
  padding: 18px 29px 18px 29px;
  border-radius: 50px;
  color: var(--black-color);
  font-size: 19px;
  font-weight: var(--fw-400);
  line-height: 22.8px;
  margin-bottom: 0.7em;
}
.add-ons-modal li label {
  cursor: pointer;
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
  line-height: var(--lh-24);
  letter-spacing: normal;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-checkbox {
  position: relative;
  width: 31px;
  height: 31px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.adonsselected .custom-checkbox {
  background-color: var(--primary-color) !important;
}

.input[type="checkbox"]:checked + .addoneselected .custom-checkbox:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: var(--primary-color);
  border-radius: 2px;
  transform: rotate(45deg);
}

.addoneselected {
  background-color: #e0f7fa;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 5px;
}
.flex-grow-1 .btn-primary {
  background-color: transparent !important;
  color: var(--black-color) !important;
}

.timer {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 700;
}
.bg-gray {
  background-color: #ebebeb !important;
}
.top-profile {
  position: relative;
  height: 172px;
  background-color: #fcfaf3;
}
.top-profile h5 {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: var(--lh-24);
  letter-spacing: 0.28em;
  text-align: center;
}
.profile-img {
  position: relative;
  margin-top: -160px;
  z-index: 111;
}
.profile-tabs {
  padding: 0 4em;
}
.profile-tabs .nav-tabs {
  border-bottom: 0px !important;
}
.profile-tabs .nav-tabs li {
  margin-right: 20px;
}
.profile-tabs .nav-tabs .nav-link {
  font-size: var(--fs-15);
  font-weight: var(--fw-700);
  line-height: 27px;
  letter-spacing: 0.17em;
  padding: 0px;
  color: var(--black-color);
}
.profile-tabs .nav-tabs .nav-link:hover,
.profile-tabs
  .nav-tabs
  .nav-link:focus
  .profile-tabs
  .nav-tabs
  .nav-link.active,
.profile-tabs .nav-tabs .nav-item.show .nav-link,
.profile-tabs .nav-tabs .nav-link.active {
  border: 0px !important;
  color: var(--primary-color);
  box-shadow: none;
  border-color: transparent !important;
}
.profile-tab-content {
  background: #d5d5d500;
  border: 1px solid #fff;
  border-radius: 15px;
  box-shadow: 0 4px 15px 0 #e9e9e999;
  padding: 3em 2em;
}
.profile-tab-content h4,
#orderhistory h4 {
  color: #131313;
  font-size: var(--fs-20);
  font-weight: var(--fw-700);
  line-height: 34px;
  letter-spacing: 1.4px;
}
#orderhistory .table thead {
  background-color: #636363;
  color: var(--white-color);
  border-radius: 8px;
}
#orderhistory .table th {
  background-color: #636363;
  color: var(--white-color);
  font-weight: var(--fw-700);
}

#orderhistory .table th:first-child {
  border-radius: 8px 0px 0px 8px;
}
#orderhistory .table th:last-child {
  border-radius: 0px 8px 8px 0px;
}
#orderhistory .table th,
#orderhistory .table tr td {
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  line-height: 25.5px;
  letter-spacing: 0.03em;
  padding: 20px;
}
.settings {
  position: relative;
  top: -70px;
  font-size: var(--fs-16);
  font-weight: var(--fs-400);
  line-height: 25.5px;
  letter-spacing: 0.03em;
}
.check-mark {
  width: 25px;
  height: 25px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 25px;
  top: 25px;
}
.check-mark img {
  width: 18px;
  text-align: center;
}
.error {
  color: var(--primary-color);
}
select {
  appearance: none; /* Removes default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent url("assets/img/arrow-down.png") no-repeat right 20px
    center;
  background-size: 15px;
}
.invoice {
  width: 1000px;
  margin: 0px auto;
  display: table;
}
.invoice h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.28em;
}

.invoice p {
  font-size: 16px;
    font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}
.invoice h6 {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 29.88px;
  letter-spacing: normal !important;
  text-align: left !important;
}

.invoice table tr td,
.invoice table tr th {
  font-size: 15px;
    font-weight: 400;
  line-height: 29.88px;
  padding: 5px 0px;
}
.invoice table tr th {
  font-weight: 400;
  padding: 10px 0px;
}
.invoice table tr td:first-child {
  padding-right: 30px;
}
.day-wise .heading {
  background-color: var(--black-color);
  color: var(--white-color);
  font-size: 19px;
  font-weight: 700;
  line-height: 28.5px;
  padding: 15px 25px;
  border-radius: 30px;
  width: 100%;
}
.contact-outer {
  width: 1160px;
  margin: 0px auto;
}
.contact-heading {
  width: 450px;
  margin: 0px auto 2em;
}
.contact-heading h4 {
  font-size: 27px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0.07em;
}
.contact-heading h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0.02em;
}
.contact-outer textarea {
  height: 200px;
}

.calculate-cost-form h4 {
  font-size: 17px;
  font-weight: 700;
  line-height: 62px;
  letter-spacing: 0.07em;
  text-align: center;
}

.calculate-cost-form {
  backdrop-filter: blur(17px);
  box-shadow: 0px 4px 44px 2px #00000012;
  border-radius: 23px;
  background: #ffffffbf;
}
.calculate-cost-form .form-control,
.home-calculate-form .form-control {
  font-family: var(--font-family-lato) !important;
  border: 1px solid #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--black-color);
  border-radius: 30px;
  text-transform: uppercase
}
.add-event-block {
  position: sticky;
  top: 0;
  z-index: 111;
}
.add-event-block  .btn-event-outline{
  background-color: var(--white-color);
}
.home-calculate-form .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input,
.home-calculate-form
  .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input::placeholder,
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
  font-family: var(--font-family-lato) !important;
  color: #000 !important;
  font-weight: 600 !important;
  opacity: 1 !important;
}

.calculate-cost-form .css-lrgvhg,
.home-calculate-form .css-lrgvhg {
  border-radius: 30px !important;
  color: var(--black-color) !important;
  font-size: 15px !important;
  font-weight: var(--fw-400) !important;
  border: 1px solid #ececec !important;
  height: 51.57px !important;
  line-height: 20px !important;
  letter-spacing: 1.05px !important;
}

.calculate-cost-form .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root,
.home-calculate-form .css-lxfshk-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 30px !important;
  color: var(--black-color) !important;
  font-size: 15px !important;
  font-weight: var(--fw-400) !important;
  border: 1px solid #ececec !important;
  height: 51.57px !important;
  line-height: 20px !important;
  letter-spacing: 1.05px !important;
}
.calculate-cost-form.css-1i2hsuj-MuiInputAdornment-root,
.home-calculate-form.css-1i2hsuj-MuiInputAdornment-root {
  color: #000 !important;
}
.calculate-cost-form .MuiFormControl-root,
.home-calculate-form .MuiFormControl-root {
  width: 100% !important;
  border-radius: 30px !important;
}
.calculate-cost-form .css-1ll44ll-MuiOutlinedInput-notchedOutline,
.home-calculate-form .css-1ll44ll-MuiOutlinedInput-notchedOutline {
  border: 1px solid #000 !important;
}
.calculate-cost-form .css-5v2ak0,
.home-calculate-form .css-5v2ak0 {
  border: 1px solid #000 !important;
}
.css-8mmkcg {
  color: #000 !important;
  width: 34px !important;
  height: 26px !important;
}
form .text-danger {
  text-align: left;
}
.service-section p {
  font-size: 19px;
  font-weight: 300;
  line-height: 34.39px;
  margin-bottom: 1.5em;
}
.service-section .left-block img {
  width: 410px;
  height: 420px;
  object-fit: cover;
  border-radius: 15px;
  opacity: 1 !important;
}

.service-section .left-block button {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  margin: 0px 5px;
  background-color: transparent !important;
  border: 0px !important;
  padding: 5px;
}

.service-section .left-block button img {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  margin: 0px 5px;
  background-color: transparent !important;
  border: 0px !important;
  padding: 5px;
  z-index: 1111 !important;
  position: relative;
}
.service-section .left-block button img:hover {
  border: 1px solid var(--black-color) !important;
}
.css-1fzpoyk {
  opacity: 1 !important;
}
.button-list {
  margin-top: -100px;
  text-align: right;
}
.css-1qzevvg img {
  display: none !important;
}
.count {
  border-radius: 50%;
  position: absolute;
  width: 18px;
  height: 18px;
  background: #000;
  right: 2px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  font-weight: 600;
  top: 11px;
  font-size: 12px;
}
.notification-list li {
  background: #fbfbfb;
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
}
.notification-list li .circle {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: relative;
  top: 5px;
  flex-shrink: 0;
}
.notification-list li p {
  font-weight: 500;
  font-size: 16px;
}
.notification-list li small {
  color: #7d7d7d;
}
.pr-5 {
  padding-right: 50px;
}
.top-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(assets/img/about/top-banner.png) 0px 0px no-repeat;
  background-size: cover;
  width: 100%;
  height: 450px;
  padding: 2em 6em;
}
.o-hidden {
  overflow: hidden;
}
.top-banner span,
.gallery-top-banner span {
  font-size: var(--fs-14);
}
.top-banner h2,
.gallery-top-banner h2 {
  font-size: 65px;
  line-height: 65px;
  font-weight: var(--fw-700);
  margin-bottom: 0.2em;
}
.top-banner p {
  font-size: 22px;
  font-weight: var(--fw-300);
  padding-left: 5em;
  padding-right: 5em;
}
.about-details p {
  font-size: 18px;
  color: #3c465f;
}
.counter {
  background-color: #2b2b2b;
  border-radius: 0px;
}
.counter-block h4 span {
  font-size: 48px;
  font-weight: var(--fw-700);
  color: #e50d0d;
}
.counter-block span {
  font-size: 18px;
  color: var(--white-color);
}
.team-block h3,
.team-block span {
  font-size: 18px;
  color: var(--black-color);
}
.team-block h3 {
  font-weight: var(--fw-700);
  margin-bottom: 0.2em;
}
.core-values .heading2 p {
  color: #3c465f;
  padding: 0em 9em;
  font-size: 18px;
}
.value-block {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 2em;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.value-block img {
  width: 58px;
  margin-bottom: 1em;
}
.value-block h3 {
  font-size: var(--fs-20);
  font-weight: var(--fw-400);
}
.value-block p {
  color: #313131;
  font-size: var(--fs-17);
  font-weight: var(--fw-300);
}

.img-outer img,
.img-outer {
  width: 100%;
  height: 450px;
  position: relative;
  object-fit: cover;
}
.img-outer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(20, 22, 36, 0.4);
}
.blog-top-banner,
.gallery-top-banner {
  position: relative;
}
.blog-top-banner .content {
  position: absolute;
  z-index: 11;
  bottom: 25px;
}
.gallery-top-banner .content {
  position: absolute;
}
.authorimg,
.profileimage {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #eee;
  object-fit: cover;
}
.content {
  position: relative;
  z-index: 11;
}
.blog-top-banner .badge-red,
.blog-detail .badge-red {
  background-color: #e50d0d;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: var(--fs-14);
  font-weight: var(--fw-400);
  color: var(--white-color);
}
.blog-top-banner h4 a {
  font-size: 24px;
  font-weight: var(--fw-700);
  color: var(--white-color);
}
.blog-detail h3 {
  font-size: 24px;
  font-weight: var(--fw-700);
  color: var(--black-color);
}
.blog-detail .quote {
  padding: 1em;
  background-color: #f6f6f7;
  font-size: 20px;
  font-style: italic;
  border-left: 4px solid #e8e8ea;
  margin: 1em 0;
  font-family: source serif pro;
  border-radius: 5px;
}

.blog-top-banner strong,
.blog-detail strong {
  font-size: var(--fs-14);
  font-weight: var(--fw-700);
}
.blog-top-banner .date,
.blog-detail .date {
  font-size: var(--fs-16);
}
.blog-list .blog-block {
  border: 1px solid #e8e8ea;
  border-radius: 12px;
  background-color: var(--white-color);
  margin-bottom: 2em;
}
.row > .col-md-4:nth-child(n + 4) .blog-block,
.gallery .row > .col-md-4:nth-child(n + 4) .block {
  margin-bottom: 0 !important;
}
.blog-list .blog-block .blog-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 6px;
}
.blog-list .blog-block h6 {
  background-color: rgba(75, 107, 251, 0.05);
  color: #e50d0d;
  font-size: var(--fs-14);
  font-weight: var(--fw-700);
  display: inline-block;
  padding: 5px 8px;
  border-radius: 5px;
}
.blog-list .blog-block h3 a {
  font-size: 22px;
  color: #181a2a;
  font-weight: var(--fw-700);
  line-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  max-height: calc(1.5em * 3);
}
.blog-list .blog-block strong {
  font-size: var(--fs-14);
  font-weight: var(--fw-700);
  color: #97989f;
}
.blog-list .blog-block .date {
  color: #97989f;
  font-size: 16px;
}
.blog-detail-content img {
  width: 100%;
  border-radius: 12px;
  margin: 1em 0;
}
.blog-detail-content h4,
.blog-detail-content h4 strong {
  color: #181a2a;
  font-size: 22px;
  font-weight: var(--fw-600);
}
.blog-detail-content p {
  font-size: var(--fs-17);
  color: #3b3c4a;
  line-height: 30px;
}
.breadcrumb li a {
  color: var(--black-color);
  font-size: 13px;
  padding: 0px 10px;
  text-align: uppercase;
}
.gallery-content h3 {
  font-size: 30px;
  font-weight: var(--fw-700);
  text-transform: uppercase;
}
.gallery-content ul li,
.gallery-content p {
  font-size: 19px;
  font-weight: var(--fw-300);
}
.gallery-content ul li strong {
  font-size: 19px;
  font-weight: var(--fw-700);
}
.gallery-content .block {
  border-radius: 12px;
  border: 1px solid #e8e8ea;
  padding: 1em;
}
.gallery-content .block img {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  object-fit: cover;
}
.service-types li {
  border: 1px solid #d9d9d9;
  padding: 0.5em 1em;
  border-radius: 30px;
}
.checkbox-list li {
  border: 1px solid #000;
  padding: 0.5em 1.4em;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.checkbox-list label {
  color: var(--black-color) !important;
  font-size: 15px !important;
  font-weight: var(--fw-400) !important;
  line-height: 20px !important;
  letter-spacing: 1.05px !important;
}

.checkbox-list .custom-checkbox {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  background-color: white;
  transition: all 0.3s ease;
}

.checkbox-list .custom-checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 10px;
  background-image: url("assets/img/event-icons/checkmark.png");
  background-size: cover;
  opacity: 0;
  transition: all 0.3s ease;
}

.checkbox-list input[type="checkbox"]:checked + label .custom-checkbox {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox-list input[type="checkbox"]:checked + label .custom-checkbox::after {
  opacity: 1;
}
.choose-add-ons {
  background-color: #f3f8fc;
  border: 1px solid #d6e1e9;
  border-radius: 20px;
}
.btn-hide {
  display: none !important;
}
.footer {
  background-color: rgba(210, 210, 210, 0.89);
}
.footer .w-11 {
  width: 11.66666667%;
}
.footer .w-30 {
  width: 30%;
}
.footer-links .btn-check:checked + .btn,
.footer-links .btn.active,
.btn.show,
.footer-links .btn:first-child:active,
.footer-links :not(.btn-check) + .btn:active {
  color: #f44336;
}
.footer h4,
.footer h4 .btn-link {
  font-size: var(--fs-16);
  font-weight: var(--fw-700);
  line-height: 41.88px;
  letter-spacing: 0.03em;
  color: var(--black-color-color);
  text-align: left;
}
.footer h4 .btn-link {
  text-decoration: none;
}
.footer .col-list {
  column-count: 2;
}
.footer-links li {
  margin-bottom: 8px;
}
.footer-links li a {
  font-size: var(--fs-14);
  font-weight: var(--fw-500);
  letter-spacing: 0.03em;
  color: #636363;
}
.footer .newsletter .form-control {
  border-top: var(--val-0);
  border-left: var(--val-0);
  border-right: var(--val-0);
  border-bottom: 1px solid var(--black-color);
  border-radius: var(--val-0);
  color: #636363;
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
  height: 45px;
}
.footer .newsletter .form-control::placeholder {
  color: #636363;
  font-size: var(--fs-16);
  font-weight: var(--fw-400);
}
.footer .btn-white-outline {
  border-radius: 53px;
  border: 1px solid var(--black-color);
  background: rgba(0, 0, 0, 0);
  color: var(--black-color);
  font-size: var(--fs-16);
  font-weight: var(--fw-500);
  width: 92px;
  height: 36px;
}
.footer .btn-white-outline:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white-color);
}
.footer .social-links {
  position: absolute;
  bottom: 0;
  right: 0;
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 30px !important;
  border-color: var(--black-color) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.slider-container {
  overflow: visible; /* Ensure the slider is visible */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  color: var(--black-color);
  font-size: 15px;
  font-weight: var(--fw-400);
  line-height: 20px;
  letter-spacing: 1.05px;
  border-radius: 30px;
  border-color: var(--black-color) !important;
  padding: 15px;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: var(--black-color) !important;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.modal-content .add-ons li {
  border: 1px solid #d9d9d9;
  padding: 1em;
  border-radius: 30px;
}
.category-list .cat-label {
  border: 1px solid var(--black-color);
  padding: 0.5em 2em;
  border-radius: var(--val-30);
  min-width: 200px;
  text-align: center;
  font-size: var(--fs-17);
  margin: 0.5em;
}
.category-list .cat-label:hover {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.mob-view {
  display: none !important;
}
.desk-view {
  display: block !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  height: 55px;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: var(--black-color) !important;
}
.modal {
  z-index: 1111;
}
.modal-open {
  padding: 10px !important;
}
.calculate-form .select-dropdown .css-1py2szx-control,
.calculate-form .select-dropdown .css-139wcki-control {
  border: 1px solid #000 !important;
  height: 48.33px !important;
  border-radius: 30px;
  padding: 2px;
}

.calculate-form .select-dropdown .css-1f43avz-a11yText-A11yText,
.calculate-form .select-dropdown .css-15nly8p-singleValue {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: var(--black-color);
  text-transform: uppercase;
}

.calculate-form .css-1nmdiq5-menu {
  text-align: left;
  font-size: 12px;
  color: var(--black-color);
  font-family: var(--font-family-lato) !important;
}

.calculate-form .select-dropdown .css-1oqp33u-placeholder {
  font-size: 12px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--black-color);
  text-transform: uppercase;
}

.calculate-form .select-dropdown .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.calculate-form .select-dropdown .css-tj5bde-Svg {
  width: 34px !important;
  height: 26px !important;
}

.service-slider {
  width: 80%;
  height: 500px;
  margin: 0 auto 2em;
}
.btn-sticky {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99999999999;
}
.tab-pane .css-1oy18r0 {
  border: 1px solid #000000;
  border-radius: 30px;
  color: var(--black-color) !important;
  font-size: 15px;
  font-weight: 400;
  font-weight: var(--fw-400);
  letter-spacing: 1.05px;
  line-height: 20px;
  height: 51px;
}
.tab-pane .css-5v2ak0 {
  border-color: transparent;
}
.home-calculate-form .css-1oy18r0,
.calculate-cost-form .css-1oy18r0 {
  border: 1px solid #ececec;
  border-radius: 30px;
  color: var(--black-color);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 35px;
  text-align: left;
  text-transform: uppercase;
}
.home-calculate-form .css-5v2ak0,
.calculate-cost-form .css-5v2ak0 {
  border-color: transparent;
  color: var(--black-color);
}
.home-calculate-form .css-qwdxx6::placeholder,
.calculate-cost-form .css-qwdxx6::placeholder {
  font-size: 12px;
  font-family: var(--font-family-lato) !important;
  color: #000 !important;
  font-weight: 600;
  opacity: 1;
}
#customize-event-form .css-qwdxx6 {
  color: #000 !important;
  opacity: 1;
}
#customize-event-form .css-1oy18r0 {
  border: 1px solid #000000;
  border-radius: 30px;
  color: #000;
  color: var(--black-color);
  font-size: 15px;
  font-weight: 400;
  font-weight: var(--fw-400);
  letter-spacing: 1.05px;
  line-height: 20px;
  height: 51px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  column-gap: 8px;
  position: fixed;
  margin: 0px auto;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: #fff;
}

.loader .dot {
  width: 12px;
  height: 12px;
  background-color: var(--black-color);
  border-radius: 50%;
  animation: loading 1s infinite alternate;
}

.loader .dot:nth-child(1) {
  background-color: var(--primary-color);
  animation-delay: -0.25s;
}

.loader .dot:nth-child(2) {
  background-color: var(--black-color);
  animation-delay: -0.5s;
}

.loader .dot:nth-child(3) {
  background-color: var(--primary-color);
  animation-delay: -0.75s;
}

.loader .dot:nth-child(4) {
  background-color: var(--black-color);
  animation-delay: -1s;
}

.custom-link {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits color from parent, or specify a color */
}
.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.close-navbar-toggler.collapsed {
  z-index: -1;
}
.navbar {
  z-index: 2;
}
@keyframes loading {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(5px);
  }
}
.latest-work-list .block img {
  height: 346px;
  object-fit: cover;
}
.latest-work-list .block h3 a {
  font-size: 22px;
  color: var(--black-color);
  line-height: 26px;
  letter-spacing: 0.07em;
  font-weight: var(--fw-600);
}
.latest-work-list .block span {
  color: #989898;
  font-size: 20px;
}
.select__control {
  border-radius: 30px !important;
  border-color: #000 !important;
  text-align: left !important;
  min-height: 50px !important;
  padding-left: 10px !important;
  color: #000 !important;
}
.select__placeholder {
  color: #000 !important;
}
.select__indicator-separator {
  display: none;
}
.css-tj5bde-Svg {
  color: #000 !important;
  fill: #000 !important;
}
.select__control option {
  text-align: left !important;
}

.title-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Restrict to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

select option:checked,
select option:hover,
select option:active {
  background-color: #86b7fe !important;
  color: white;
}

.info-section {
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  margin: 0 auto;
  width: 80%;
}

.info-item {
  text-align: center;
  font-size: 16px;
  color: #000;
}

.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.asterisk {
  font-size: 12px;
  vertical-align: super;
}

.terms {
  font-size: 12px;
  color: #888;
  text-align: right;
  width: 100%;
  margin-top: 10px;
}

.wrapped-text {
  max-width: 200px; /* Adjust width as needed */
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; /* Ensures the text wraps instead of staying in a single line */
}
.btn-check:checked+.btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check)+.btn:active:focus-visible {
  box-shadow: none;
  border: 0px;
}
button {
  transition: none !important; 
  transform: none !important;
}

button:focus, button:active {
  transform: none !important;
  outline: none !important; 
}

@media (max-width: 1500px) {
  #review-order .payble-now,
  #review-order .total-pay-list {
    font-size: 16px;
  }
  .btn-sm-outline {
    font-size: 14px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    letter-spacing: 0px;
  }
}
@media (max-width: 1399px) {
  .about .col-md-9 {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .check-mark {
    top: 17px;
  }
  #review-order .p-block {
    min-height: 115px;
    padding: 10px !important;
  }
  #review-order .pay-list li span.s-text {
    width: 215px;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    width: 95%;
  }

  .event-tab-content h4 {
    font-size: 16px;
  }
  .navbar .text-hide {
    display: none;
  }
  .navbar .gap-3 {
    gap: 0.4rem !important;
  }
  .navbar-brand img {
    width: 250px;
  }
  .navbar-nav .btn-main-outline {
    padding: 11px 20px;
  }
  #customize-event form label,
  #review-order label,
  #customize-event .number-list a {
    font-size: 13px;
    line-height: 28px;
  }
  #customize-event .nav-tabs .nav-item .nav-link,
  #customize-event .nav-tabs .nav-item .nav-link.active {
    padding: 0.8em;
  }
  form .mb-5 {
    margin-bottom: 1.5rem !important;
  }
  #customize-event .add-ons {
    margin-top: 1.5rem !important;
  }
  .event-tab-content .tab-content {
    padding-bottom: 0em;
    margin-top: 0px !important;
    padding-top: 2.5em !important;
  }
  .event-tab-content .event-item {
    height: 160px;
  }
  .event-tab-content {
    overflow: visible;
  }
  .event-tab-content .invoice{
    overflow: hidden;
  }
  .event-tab-content h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2.76px;
    height: 40px;
  }
  #review-order .pay-list li span {
    font-size: var(--fs-16);
  }
  #review-order .col-sm-5 {
    width: 50%;
  }
  #review-order .col-sm-7 {
    width: 50%;
  }
  .tab-pane .container {
    width: 100%;
  }
  .tab-pane .row.px-5 {
    padding-left: 1.5 !important;
    padding-right: 1.5 !important;
  }
  #customize-event {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .invoice {
    width: 90%;
    margin: 0px auto;
    display: block;
  }
  .blog-list .blog-block h3 {
    font-size: 20px;
  }
  .blog-list .blog-block h6 {
    font-size: 13px;
  }
  .blog-list .blog-block .date {
    font-size: 14px;
  }
  .testimonial-block .testi-img:after {
    width: 280px;
  }
  .testimonials .t-heading {
    left: 45%;
  }
  .why-choose-us h5 {
    font-size: var(--fs-15);
  }

  .why-choose-us p {
    font-size: 14px;
    line-height: 18.76px;
  }
  .why-choose-us .border {
    padding: 0.5em 0.1em !important;
  }
  .why-choose-us .border img {
    width: 40px;
    height: 40px;
  }
  .main-slider .slider-img {
    height: 350px;
  }
  .home-calculate-form img {
    width: 300px;
  }
  .p-1-0 {
    padding: 1em 0em !important;
  }
  .service-slider {
    width: 50%;
  }
  .button-list {
    margin-top: -90px;
    text-align: center;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    width: 100%;
    max-width: 96%;
  }
  .modal-dialog {
    max-width: 800px !important;
  }
  .close-btn {
    height: auto;
    padding: 15px;
    font-size: 13px;
    letter-spacing: 2px;
    right: 55px;
  }
  .close-btn img {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 1080px) {
  .modal-dialog {
    max-width: 95% !important;
  }
  .top-banner h2 {
    font-size: 58px;
    line-height: 60px;
  }
  .testimonial-block .testi-img:after {
    width: 245px;
  }
  .packages.row.px-5 {
    padding: 0px !important;
  }
  .package-block img {
    height: 200px;
  }
  .modal-dialog {
    min-height: auto;
  }
  .invoice {
    width: 100%;
  }
  .footer-logo img {
    width: 200px;
  }
  .navbar-brand img {
    width: 200px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
    letter-spacing: 0px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .btn-skip,
  .btn-skip:hover,
  .btn-skip:focus,
  .btn-skip:checked + .btn,
  .btn-skip.active,
  .btn-skip.show,
  .btn-skip:first-child:active,
  :not(.btn-skip) + .btn-skip:active {
    padding: 11px 25px;
    font-size: 12px;
    height: 46px;
    line-height: var(--val-26) !important;
  }
  .css-3v9qdb-MuiTooltip-tooltip {
    width: 155px !important;
  }
  .mob-table tr,
  .mob-table td {
    display: block !important;
  }
  .add-event .mb-4 {
    margin-bottom: 0.5rem !important;
  }
  .btn-main,
  .btn-main:hover,
  .btn-main:focus,
  .btn-main:checked + .btn,
  .btn-main.active,
  .btn-main.show,
  .btn-main:first-child:active,
  :not(.btn-main) + .btn-main:active,
  .btn-main2,
  .btn-main2:hover,
  .btn-main2:focus,
  .btn-main2:checked + .btn,
  .btn-main2.active,
  .btn-main2.show,
  .btn-main2:first-child:active,
  :not(.btn-main2) + .btn-main2:active {
    padding: 11px 15px;
    font-size: 12px;
    height: 46px;
    line-height: var(--val-26) !important;
  }
  #accordion .card .card-header a {
    padding: 11px 25px;
  }
  .package-block .btn-main-outline {
    padding: 11px 14px;
  }
  .mob-view {
    display: block !important;
  }
  .desk-view {
    display: none !important;
  }
  .mob-nav {
    position: absolute;
    top: 20px;
    right: 80px;
  }
  .mob-nav li:first-child {
    margin-right: 15px;
  }
  .mob-nav .dropdown-toggle::after {
    display: none;
  }
  .footer .w-11 {
    width: 100%;
  }
  .footer .w-30 {
    width: 100%;
  }
  .footer .col-md-3 {
    width: 100%;
  }

  #make-payment .payment-block {
    padding: 1.5em;
  }
  #make-payment .col-sm-5,
  #make-payment .col-sm-7 {
    width: 100%;
  }
  .img-outer img,
  .img-outer {
    height: 350px;
  }
  .w-92 {
    width: 92%;
  }
  .top-banner h2,
  .gallery-top-banner h2 {
    font-size: 45px;
    line-height: 45px;
  }
  .mob-100 {
    width: 100%;
  }
  .blog-top-banner,
  .blog-top-banner:after {
    height: 350px;
  }
  .blog-list .blog-block .blog-img {
    height: 150px;
  }
  .blog-list .blog-block h3 {
    font-size: 17px;
  }
  .top-banner {
    padding: 2em;
  }
  .value-block p {
    font-size: var(--fs-15);
  }
  .about .col-md-9,
  .blogs .col-md-10,
  .gallery-detail .col-md-10 {
    width: 100%;
  }
  .slider .container-fluid {
    padding: 0px 30px !important;
  }
  .testimonials {
    overflow: hidden;
  }
  .testimonials .t-heading {
    position: relative;
    left: auto !important;
    text-align: center;
  }
  .testi-content {
    box-shadow: none;
    top: 0px;
    padding: 0px !important;
  }
  .testimonial-block.d-flex {
    flex-direction: column;
  }
  .main-slider {
    margin-bottom: 5em;
  }
  .testimonial-block .testi-img:after {
    width: 200px;
    height: 220px;
  }
  .testimonials .slick-prev,
  .testimonials .slick-next {
    top: auto !important;
    bottom: -60px !important;
  }
  .testimonials .slick-prev {
    right: 50%;
  }
  .testimonials .slick-next {
    left: 50%;
  }
  .testimonial-block .testi-img img {
    width: 200px;
    height: 220px;
    margin-bottom: 2em;
  }
  .testimonial-block .testi-img {
    width: auto;
    right: 0;
    text-align: center;
  }
  .main-slider .slider-img {
    height: auto;
  }
  .package-block h4 {
    font-size: var(--fs-14);
    font-weight: var(--fw-600);
    line-height: 20px;
    letter-spacing: 0px;
  }
  .package-block img {
    height: 165px;
  }
  .why-choose-us .right-block {
    width: 70%;
  }
  .why-choose-us .left-block {
    width: 30%;
  }
  .modal-body.pt-5 {
    padding-top: 0px !important;
  }
  .service-slider {
    width: 55%;
  }
  .service-section .pr-5 {
    padding-right: 0px;
  }
  .footer .col-list li,
  .footer .footer-links li {
    display: block;
  }
  .payment-block .px-5 {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }
  .navbar {
    height: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    line-height: 18px;
    font-size: 12px;
    letter-spacing: 0px;
    /* text-align: center; */
    padding: 6px;
    width: 82px;
  }

  .navbar-expand-lg .navbar-toggler .collapse:not(.show) {
    display: none !important;
  }

  .navbar-expand-lg .navbar-toggler:focus {
    outline: 0;
    box-shadow: none;
  }
  .navbar-nav .btn-main-outline {
    padding: 11px 10px;
  }

  .event-item img {
    width: 50px;
    height: auto;
  }
  .event-tab-content h6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2px;
    height: 30px;
  }
  .event-tab-content .event-item {
    height: 140px;
  }
  .btn-custom-mr {
    margin-right: 0px;
  }
  .container,
  .container-md,
  .container-sm {
    max-width: 95%;
  }
  /* .btn-main,
  .btn-main:hover,
  .btn-main:focus,
  .btn-main:checked + .btn,
  .btn-main.active,
  .btn-main.show,
  .btn-main:first-child:active,
  :not(.btn-main) + .btn-main:active,
  .btn-edit {
    font-size: 13px;
    padding: 10px 20px;
  } */
  .event-tab-section .nav-tabs .nav-link,
  .event-tab-section .nav-tabs .nav-link.active {
    width: 150px;
  }
  .event-tab-content .float-right {
    float: none;
    margin: 0px !important;
  }
  .mb-8 {
    margin-bottom: 2em;
  }
  .contact-outer {
    width: 100%;
  }

  .footer .col-md-4,
  .footer .col-md-2,
  .footer .col-md-6 {
    width: 100%;
  }
  .footer .footer-links {
    margin-bottom: 0.8em;
  }
  .footer .footer-links ul {
    column-count: 5;
    margin: 0px auto;
  }
  .footer .social-links {
    margin-top: 40px;
    position: relative;
  }
  .social-links .d-flex {
    justify-content: center !important;
  }
  #review-order .col-md-7,
  #review-order .col-md-5 {
    width: 100%;
  }
  #review-order .payment-method {
    margin-top: 2em !important;
  }
  #review-order .row.px-5 {
    padding: 0px !important;
  }
  .navbar-expand-lg .navbar-toggler {
    display: inline-block;
    border: 0px;
  }
  .navbar-toggler-icon {
    filter: invert(0) brightness(0);
  }

  .navbar-collapse {
    z-index: 9999999;
    background-color: #fff;
    width: 100%;
    top: 5em;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    color: var(--black-color);
    width: auto;
  }
  .navbar .btn-main-outline {
    margin-bottom: 25px;
  }
  .navbar .gap-3 {
    align-items: flex-start !important;
    padding: 1em 1em 0;
  }
  .navbar li:last-child {
    width: 100%;
  }
  .count {
    right: 0px;
    top: 3px;
  }
  .navbar .btn-main-outline {
    width: 100%;
  }
  .footer-logo {
    text-align: center;
    margin: 0px auto;
    display: table;
  }
  .w-50 {
    width: 90% !important;
  }
  .profile-tabs {
    padding: 0 0em;
  }

  .modal-header.pt-5 {
    padding-top: 2rem !important;
  }
  .close-btn {
    right: 20px;
  }
}
@media (max-width: 768px) {
  .notifications .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .package-block .btn-main-outline {
    padding: 11px 16px;
  }
  .package-block {
    padding: 1em;
  }
  .blog-list .blog-block .blog-img {
    height: 100%;
  }
  .about .py-5 {
    padding: 2em 0 !important;
  }
  .counter-block,
  .team-block,
  .value-block {
    margin-bottom: 2em;
  }
  .counter .col-md-4:last-child .counter-block,
  .team .col-md-4:last-child .team-block,
  .core-values .col-md-4:last-child .value-block {
    margin-bottom: 0em;
  }
  .latest-work-list .block img {
    height: 300px;
  }
  .invoice h5 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .day-wise td {
    min-width: auto;
}
  #accordion .card .card-header a{
    font-size: 14px;
    font-weight: var(--fw-700);
  }
  .mob-mb-1 {
    margin-bottom: 1em !important;
  }
  .performa {
    position: relative !important;
    bottom: 0px !important;
  }
  #make-payment .day-wise .mb-4 {
    margin-bottom: 1em !important;
  }
  .invoice {
    padding-top: 0 !important;
  }
  .mob-text-left {
    text-align: left !important;
  }
  .footer .btn-link span.ms-2 {
    position: relative;
    right: 10px;
  }
  .footer .newsletter {
    margin-top: 2em;
  }
  .newsletter form div {
    position: relative;
  }
  .newsletter .btn-white-outline {
    position: absolute;
    right: 0px;
    bottom: 9px;
  }
  .footer .footer-links {
    margin-bottom: 0.5em;
    border-bottom: 1px solid #000;
  }
  .footer h4 .btn-link {
    color: #636363;
  }
  #make-payment .px-5 {
    padding: 0px !important;
  }
  .footer .footer-links ul {
    column-count: auto;
  }
  .footer-logo {
    margin: 0px;
  }
  .social-links .d-flex {
    justify-content: flex-start !important;
  }
  .about .container-fluid.p-0,
  .blogs .container-fluid.p-0,
  .gallery-detail .container-fluid.p-0 {
    padding: 0px !important;
  }
  .gallery-detail .mt-3,
  .blogs .mt-3,
  .about .mt-3 {
    margin-top: 0rem !important;
  }
  .gallery-top-banner .content {
    padding: 0 2em;
  }
  .blogs .content {
    left: 25px;
    right: 25px;
  }
  .top-banner p {
    padding-left: 0;
    padding-right: 0;
  }
  .c-block {
    margin-bottom: 2em;
  }
  .core-values .heading2 p {
    padding: 0;
  }
  .value-block {
    min-height: auto;
  }

  .number-list,
  .checkbox-list {
    display: flex !important;
    overflow-x: auto;
    white-space: nowrap !important;
    -webkit-overflow-scrolling: touch;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap !important;
  }
  .number-list > *,
  .checkbox-list > * {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
  }

  .number-list::-webkit-scrollbar,
  .checkbox-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .number-list::-webkit-scrollbar-track,
  .checkbox-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  .number-list::-webkit-scrollbar-thumb,
  .checkbox-list::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .number-list::-webkit-scrollbar-thumb:window-inactive,
  .checkbox-list::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }

  .number-list li,
  .checkbox-list li {
    display: inline-block;
    margin-right: 5px;
    white-space: nowrap;
    margin-top: 8px;
  }

  .number-list li:last-child,
  .checkbox-list li:last-child {
    margin-right: 0;
  }
  .choose-add-ons.d-flex {
    flex-direction: column !important;
    align-items: start !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .categories.py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .how-it-works {
    overflow: hidden;
  }
  .quote.py-5 {
    padding-bottom: 0px !important;
  }
  .slider.my-5 {
    margin-top: 1em !important;
    margin-bottom: 0px !important;
  }
  .slick-next,
  .slick-prev {
    top: -10px !important;
  }
  .quote-area,
  .quote-content {
    width: 90%;
  }
  .multislider .container.px-5,
  .faq .container.px-5,
  .packages .row.px-5 {
    padding: 0 1em !important;
  }
  .how-it-works .container.px-5 {
    padding: 0 !important;
  }
  .heading2 h2 span {
    display: inline;
  }
  .heading2 h2 {
    margin-bottom: 1em !important;
  }
  .recent-slider-item {
    padding: 0px 10px;
  }
  .heading {
    width: 100%;
  }
  .why-choose-us .border {
    padding: 0.5em !important;
  }
  .why-choose-us {
    flex-direction: column;
  }
  .why-choose-us .left-block img {
    height: auto;
  }
  .why-choose-us .right-block,
  .why-choose-us .left-block {
    width: 100%;
  }
  .why-choose-us .right-block {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
  .package-block {
    margin-bottom: 3em;
  }
  .package-block img {
    height: 300px;
  }

  .modal-body.mt-5,
  .modal-body.mt-4 {
    margin-top: 0px !important;
  }
  .container-fluid {
    padding: 0px 20px !important;
  }

  .modal-body {
    padding-bottom: 1em !important;
  }
  .service-section {
    margin: 0px !important;
  }
  .service-section .py-5 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .service-section .left-block img {
    width: 300px;
    height: 325px;
  }
  .button-list {
    margin-top: -70px;
  }
  .service-slider {
    height: 370px;
  }
  .event-tab-content {
    box-shadow: none;
  }
  .custom-modal label {
    font-size: var(--fs-14);
  }

  .close-btn {
    right: 10px;
    top: 10px;
    height: auto;
    width: 40px;
  }
  .close-btn span {
    display: none;
  }

  .mt-m-90 {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
  .day-wise .w-100 .d-flex {
    flex-direction: column;
  }
  .day-wise .w-100 .col-md-12 {
    padding: 0px;
  }
  .day-wise .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  #review-order .mb-4 {
    margin-bottom: 1em !important;
  }
  .payment-method {
    display: none;
  }
  #orderhistory .table th {
    min-width: 120px;
  }
  .profile-name h5 {
    font-size: var(--fs-16);
    font-weight: var(--fw-400);
    line-height: var(--lh-24);
    letter-spacing: 0.28em;
    text-align: center;
    margin-bottom: 3em;
  }
  .top-profile {
    height: 120px;
  }
  .mob-hide {
    display: none;
  }
  .profile-img {
    position: relative;
    margin: -160px auto;
    z-index: 1;
    display: table;
    width: 100%;
    text-align: center;
  }
  .mob-flex {
    flex-direction: column;
    position: relative;
  }
  .settings {
    position: absolute;
    right: 0px;
  }
  .profile-tab-content {
    background: #d5d5d500;
    border: 0px solid #fff;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
  }
  .profile-tab-content .tab-pane .col-md-12 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .profile-tabs .nav-tabs {
    display: flex;
    justify-content: center;
    gap: 2em;
    align-items: center;
    text-align: center;
  }
  .profile-tabs .nav-tabs li {
    margin-right: 0px !important;
    text-align: center;
  }

  .video-logo {
    width: 200px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    width: auto;
    /* text-align: center; */
  }
  .event-tab-content .gap-5 {
    gap: 1.5rem !important;
  }
  .event-tab-content .event-item {
    width: 27%;
  }
  .nav-tabs.gap-5 {
    gap: 1rem !important;
  }
  .event-tab-section .nav-tabs .nav-link,
  .event-tab-section .nav-tabs .nav-link.active {
    width: 130px;
  }
  .payment-method .col-md-3 {
    width: 50%;
    margin-bottom: 2em;
  }
  #review-order .p-block {
    min-height: 100px;
  }
  #review-order .p-block .payment-label {
    font-size: 15px;
    line-height: 20px;
  }
  .tab-pane.container {
    width: 97%;
    max-width: 97%;
    padding: 0px !important;
  }
  .btn-event-outline {
    font-size: var(--fs-15);
  }
  .modal-body .form-control {
    width: 100%;
  }
  .modal-body.pb-5 {
    padding-bottom: 0px !important;
  }
  .modal-body form {
    width: 100%;
  }
  .w-560 {
    width: 100%;
  }
  .timer span {
    font-size: 12px;
  }
  .modal-header.pt-5 {
    padding-top: 2rem !important;
  }
  .contact-heading {
    width: 90%;
  }
  .contact-heading h1 {
    font-size: 38px;
    line-height: 54px;
  }
  .modal-body.px-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .modal-content .add-ons li {
    padding: 0.5em 1em;
  }
}
@media (max-width: 680px) {
  .top-banner h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .package-block img {
    height: 300px;
  }
  .occassion-slider .slider-item img.w-100 {
    height: 300px;
  }
  #customize-event .add-ons {
    flex-direction: column;
    justify-content: center !important;
  }
  .event-tab-section .nav-tabs .nav-link,
  .event-tab-section .nav-tabs .nav-link.active {
    width: 115px;
  }
}
@media (max-width: 600px) {
  .occassion-slider .slider-item {
    margin: 0px;
  }
}
@media (max-width: 576px) {
  .table-responsive {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: auto;
    scrollbar-color: #ccc transparent;
  }

  .table-responsive::-webkit-scrollbar {
    height: 8px;
  }

  .table-responsive::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  .table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }

  .table-responsive::-webkit-scrollbar-track {
    background: transparent;
  }
  .footer .footer-links ul {
    column-count: 1;
    width: 100%;
  }
  .btn-hide {
    display: block !important;
  }
  .top-banner h2,
  .gallery-top-banner h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .latest-work-list .block img,
  .gallery-content .block img {
    height: auto;
  }
  .counter-block h4 span {
    font-size: 30px;
  }
  .top-banner h2 {
    font-size: 35px;
    line-height: 39px;
  }
  .top-banner p {
    font-size: 18px;
  }
  .about-details p {
    font-size: 16px;
  }
  .tab-pane.container {
    position: relative;
  }
  .event-tab-content .float-right {
    position: sticky;
    background-color: #fff;
    padding: 1em;
    bottom: 0px;
    z-index: 111;
    width: 100%;
    left: 0;
    right: 0;
  }
  .event-tab-content .float-right .d-flex {
    justify-content: center;
  }
  .modal-dialog {
    margin: 20px auto;
  }
  .check-mark {
    left: 10px;
  }

  .event-tab-section .nav-tabs:after {
    display: none;
  }
  .btn-custom-mr .btn {
    width: 100%;
    margin: 0px auto;
  }

  .navbar .container-fluid {
    flex-direction: row;
  }

  .event-tab-content .event-item {
    width: 45%;
  }
}
@media (max-width: 520px) {
  .quote-content h1 {
    font-size: var(--fs-20);
    font-weight: var(--fw-500);
  }
  .calculate-cost-form {
    padding: 1em 1.5em 2em !important;
  }

  .event-tab-content {
    box-shadow: none;
  }
  .event-tab-content .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  #customize-event .nav-tabs .nav-item {
    width: 100%;
  }
  .event-tab-section .nav-tabs .nav-link,
  .event-tab-section .nav-tabs .nav-link.active {
    width: 70px;
  }
  .event-tab-section .nav-tabs .nav-link strong {
    font-size: 11px;
    line-height: 18px;
  }
  .event-tab-section .nav-tabs .nav-item.show .nav-link,
  .event-tab-section .nav-tabs .nav-link.active,
  .event-tab-section .nav-tabs .nav-link:hover {
    border-bottom-color: transparent;
  }
}
@media (max-width: 480px) {
  .accordion-button {
    font-size: var(--fs-15);
  }
  .package-block img {
    height: 250px;
  }
  .heading h2,
  .heading2 h2 {
    font-size: 25px;
  }
  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
  }
  .occassion-slider .slider-item img.w-100 {
    height: 250px;
  }
  .custom-modal label {
    line-height: 20px;
  }
  .custom-checkbox {
    width: 25px;
    height: 25px;
  }
  .service-section .left-block img {
    width: 250px;
    height: 270px;
  }
  .button-list {
    margin-top: -80px;
  }
  .service-slider {
    height: 350px;
  }
  #review-order .pay-list li span.s-text {
    width: 150px;
  }

  .container-fluid {
    padding: 0px 15px;
  }
  .contact-heading {
    width: 100%;
  }
  .profile-tabs .nav-tabs li {
    width: 150px;
  }
}
@media (max-width: 420px) {
  .event-tab-content .float-right .d-flex {
    flex-direction: column !important;
  }
  .package-block h4 {
    font-size: var(--fs-15);
  }
  .package-block p {
    font-size: var(--fs-14);
    line-height: 21.14px;
  }
  .package-block img {
    height: 250px;
  }
  .quote-content p {
    font-size: var(--fs-14);
    line-height: 23px;
  }
  .categories .cat-img img {
    height: 250px;
  }
}
@media (max-width: 380px) {
  .navbar-brand img {
    width: 170px;
  }
  .container-fluid {
    padding: 0px 10px !important;
  }
  .mob-nav {
    right: 60px;
  }
  .home-calculate-form .px-4 {
    padding: 0px !important;
  }
  .home-calculate-form img {
    width: 250px;
  }
  .occassion-slider .slider-item img.w-100 {
    height: 200px;
  }
  .package-block img {
    height: 200px;
  }
  .service-section .left-block img {
    width: 200px;
    height: 220px;
  }
  .button-list {
    margin-top: -70px;
  }
  .service-slider {
    height: 280px;
  }
  .profile-tabs .nav-tabs {
    gap: 1em;
  }
  .profile-tabs .nav-tabs li {
    width: 130px;
  }
}

@media (max-width: 360px) {
  .btn-main-outline {
    letter-spacing: 0;
  }
  .event-tab-content .gap-5 {
    gap: 2rem !important;
  }
  .event-tab-content .event-item {
    width: 90%;
  }

  .nav-tabs.gap-5 {
    gap: 0.4rem !important;
  }
}
